import CONFIG from '../../config';
import xhr, { postCredentials } from './xhr';

import ROUTES from './routes';

class Api {
  KEY = 'smartway-bo-state';
  requestReduction = 10;
  currentAuthState = null;
  exp = 0;
  refreshTokenPromise = null;

  constructor() {
    const store = window.localStorage.getItem(this.KEY);

    this.setCurrentAuthState = store ? JSON.parse(store) : {};
  }

  static currentTimestamp = () => Math.ceil(new Date().valueOf() / 1000);

  static getToken = (params) => xhr.sendJSONWithoutToken(CONFIG.LOGIN, params);

  login = (username, password) => {
    const paramsForRefreshToken = {
      grant_type: 'password',
      client_id: 1,
      client_secret: CONFIG.AUTH.CLIENT_SECRET,
      username,
      password,
    };

    return new Promise((resolve, reject) => {
      Api.getToken(paramsForRefreshToken).then((res) => {
        const isBackofficeManager = JSON.parse(atob(res.access_token.split('.')[1])).is_backoffice_manager;

        if (isBackofficeManager) {
          this.setCurrentAuthState = res;
          window.localStorage.setItem(this.KEY, JSON.stringify(res));

          resolve(200);
        }

        reject({ status: 401 });
      })
        .catch((err) => {
          reject(err);
        });
    });
  };

  logout = () => {
    window.localStorage.removeItem(this.KEY);
  };

  updateToken = () => {
    const paramsForRefreshToken = {
      grant_type: 'refresh_token',
      client_id: 1,
      client_secret: CONFIG.AUTH.CLIENT_SECRET,
      refresh_token: this.currentAuthState.refresh_token,
    };

    this.refreshTokenPromise = new Promise((resolve) => {
      Api.getToken(paramsForRefreshToken).then((res) => {
        this.setCurrentAuthState = res;
        window.localStorage.setItem(this.KEY, JSON.stringify(res));

        resolve();
        this.refreshTokenPromise = null;
      })
        .catch((err) => {
          if (err.status === 403 || err.status === 401) {
            window.localStorage.removeItem(this.KEY);

            return window.Rise.navigate('/login');
          }

          console.error('Ошибка на запрос серверу обновить токен ', err.status);

          this.refreshTokenPromise = null;

          return null;
        });
    });
  };

  requestWithFreshToken(request) {
    const token = this.currentToken;
    const signOutAlien = () => {
      window.localStorage.removeItem(this.KEY);

      console.error('Каким-то образом тут оказался чудак без прав на пользование БО');

      window.Rise.navigate('/login');
    };

    if (token) {
      return request(token).catch((err) => {
        if (err.status === 401) {
          signOutAlien();
        }

        return new Promise((resolve, reject) => { reject(err); });
      });
    }
    if (!this.currentAuthState.refresh_token) {
      return new Promise((_, reject) => reject('No refresh token spotted'));
    }

    if (!this.refreshTokenPromise) {
      this.updateToken();
    }

    return new Promise((resolve, reject) => {
      this.refreshTokenPromise.then(() => {
        request(this.currentToken)
          .then((res) => resolve(res))
          .catch((err) => {
            if (err.status === 401) { signOutAlien(); }

            reject(err);
          });
      });
    });
  }

  set setCurrentAuthState(state) {
    this.currentAuthState = { ...state };

    if (this.currentAuthState && this.currentAuthState.access_token) {
      const payload = this.currentAuthState.access_token.split('.')[1];
      const decodePayload = atob(payload);
      const payloadObj = JSON.parse(decodePayload);

      this.exp = (payloadObj.iat + payloadObj.exp) - this.requestReduction;
    }
  }

  get currentUser() {
    return this.currentAuthState;
  }

  get currentToken() {
    if (Api.currentTimestamp() < this.exp) {
      return this.currentAuthState.access_token;
    }

    return null;
  }

  get UserName() {
    const self = this;

    return {
      getUserName() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/email`));
      },
    };
  }

  get FeatureFlags() {
    const self = this;

    return {
      getAllFeatureFlags() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/feature-flags/bo-flags`)
        ));
      },

      getSettingsFlags(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/feature-flags/account-settings-get/${accountId}`)
        ));
      },
    };
  }

  get AmoCRM() {
    const self = this;

    return {
      uploadContacts(file, tag) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(token, `${CONFIG.API_CLUSTER}/amocrm/upload?tag=${tag}`, file)
        ));
      },
      processAmoReport(file) {
        return self.requestWithFreshToken((token) => xhr
          .processAmoReport(token, `${CONFIG.API_CLUSTER}${ROUTES.AMOCRM.DUPLICATE_REPORT}`, file));
      },
      getInfoContacts(dataContacts) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.AMOCRM.GET_INFO_CONTACTS}`, dataContacts)
        ));
      },
      downloadAmoContacts(id) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.AMOCRM.DOWNLOAD_CONTACTS(id)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getSettingFunnelsTarget() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_CLUSTER}/amocrm/pipelines`));
      },
      getSettingFunnelsDuplicate(targetPipelineId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/amocrm/rules/${targetPipelineId}`)
        ));
      },
      getSaveSettingFunnels(rules) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/amocrm/rules`, rules));
      },
    };
  }

  get Users() {
    const self = this;

    return {
      getUserRoles() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/userroles`));
      },
      getUsers(status) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/Users`, { Status: status })
        ));
      },
      approveUser(userId, email) {
        const body = {
          UserId: userId,
          Email: email,
        };

        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/account/approve`, body));
      },
      rejectUser(userId) {
        return self.requestWithFreshToken((token) => xhr.putJSON(token, `${CONFIG.API_ROOT}/Users/${userId}/Reject`));
      },
      registerUser({
        Name,
        Email,
        Phone,
        Role,
        AggregatorId,
      }) {
        const form = {
          user_name: Name,
          phone_number: Phone,
          account_id: 0,
          email: Email,
          default_role: Role,
          aggregator_id: AggregatorId,
        };

        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.USER_MANAGER}/addUser`, form));
      },
      registerSAUser(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.USER_MANAGER}${ROUTES.USERS.ADD_S_A_USER}`, data)
        ));
      },
      registerKZUser(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.USER_MANAGER}${ROUTES.USERS.BRAND_KZ}`, data)
        ));
      },
      registerSAAutoUser(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.USER_MANAGER}${ROUTES.USERS.AUTO_REGISTER}`, data)
        ));
      },
      searchUsers(value) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/users/find?query=${value}`)
        ));
      },
      getNote(userId) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/users/${userId}/note`));
      },
      getUser(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/users/usercompanies?userId=${userId}`)
        ));
      },
    };
  }

  get Cart() {
    const self = this;

    return {
      getCarts(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.CART_BO(userId)}`)
        ));
      },

      getApprovals(cartId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.GET_APPROVALS(cartId)}`)
        ));
      },

      getUserInfo(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.USER_INFO(userId)}`)
        ));
      },

      sendToApprove(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.CART.APPROVE_ITEM}`, data)
        ));
      },

      createCart(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.CREATE_CART}`, data)
        ));
      },

      createItem(cartId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.CREATE_ITEM_CART(cartId)}`, data)
        ));
      },

      editItem(cartId, cartItemId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.EDIT_ITEM_CART(cartId, cartItemId)}`, data)
        ));
      },

      deleteCart(cartId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.CART_BO(cartId)}`)
        ));
      },

      deleteItem(cartId, cartItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.CART_BO(cartId)}/${cartItemId}`)
        ));
      },

      sendAnalytics(cartId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.SEND_ANALYTICS(cartId)}`, data)
        ));
      },

      deleteAnalytic(cartId, analyticId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.DELETE_ANALYTICS(cartId, analyticId)}`)
        ));
      },

      saveIntercomLink(cartId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.SAVE_INTERCOM_LINK(cartId)}`, data)
        ));
      },
      getBookingRequestIdByCartId(cartId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.GET_BOOKING_REQUEST_BY_CART_ID(cartId)}`)
        ));
      },
    };
  }

  get Companies() {
    const self = this;

    return {
      searchAccounts(params) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.COMPANIES.SEARCH_ACCOUNTS}`, params)
        ));
      },
      // сжать все поездки
      clearCacheCompanies() {
        return self.requestWithFreshToken((token) => xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/trips/compress`));
      },
      searchOrders(value, active) {
        const params = {
          query: value,
          onlyActive: active,
        };

        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/trips/search`, params));
      },
    };
  }

  get Employees() {
    const self = this;

    return {
      searchEmployees(value) {
        const params = { query: value };

        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/employees/find`, params));
      },
    };
  }

  get Employee() {
    const self = this;

    return {
      // получение всех сотрудников
      getAllEmployee(account_id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.EMPLOYEE.GET_ALL_EMPLOYEE(account_id)}`)
        ));
      },
      getEmployee(employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/employee-with-account-info/${employeeId}`)
        ));
      },
      getEmployeeTrips(companyId, employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/employees/${employeeId}/trips`)
        ));
      },
      getEmployeeTripsByAccountId(accountId, employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(
            token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_EMPLOYEE_TRIPS_BY_ACCOUNT_ID(accountId, employeeId)}`,
          )
        ));
      },
      updateNotes(notes) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/employee/updateNotes`, notes)
        ));
      },
      // получение компаний юзера
      getEmployeeCompanies(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/user-service/user/${userId}/companies`)
        ));
      },
    };
  }

  get Calculator() {
    const self = this;

    return {
      getInfo() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/airline-tax-service/settings`)
        ));
      },
      saveInfo(item) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/airline-tax-service/settings`, item)
        ));
      },
    };
  }

  get Trip() {
    const self = this;

    return {
      // отмена ваучера по услуге вип-зал/услуги сопровождения
      cancelVipHallVoucher(tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.VIP_HALL.CANCEL_VOUCHER(tripItemId)}`)
        ));
      },
      // проверка возможности возвращения средств на баланс аккаунта и получение размера возвращаемых средств по такси
      getRefundVipHall(tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.VIP_HALL.GET_REFUND(tripItemId)}`)
        ));
      },
      // проверка возможности возвращения средств на баланс аккаунта и получение размера возвращаемых средств по такси
      getRefundTaxi(tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAXI.GET_REFUND_CANCEL(tripItemId)}`)
        ));
      },
      // отмена ваучера по услуге “Такси”
      cancelTaxiVoucher(tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAXI.CANCEL_VOUCHER(tripItemId)}`)
        ));
      },
      // получение данных по отелю A&A для автокомплита
      getHotelAA(hotelId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.GET_HOTEL_AA(hotelId)}`)
        ));
      },
      // получение статуса для редактирования юрлиц в поздке
      getStatusEditLegalEntity(tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_LEGAL_STATUS(tripItemId)}`)
        ));
      },
      // отправка юрлица A&A
      sendLegalEntity(data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.SEND_LEGAL_ENTITY}`, data)
        ));
      },
      // получение списка юрлиц A&A и договоров с ним
      getLegalEntityList() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_LEGAL_LIST}`)
        ));
      },
      // получение поездки
      getOrder(companyId, tripId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/${tripId}`)
        ));
      },
      getTotalTripBill(companyId, tripId, format) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/trip-service/companyId/${companyId}/trip/${tripId}/${format}`)
        ));
      },
      getFileTotalTripBill(guid) {
        return xhr.downloadJSON(`${CONFIG.API_CLUSTER}/files/file`, guid);
      },
      startEdit(companyId, tripId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/${tripId}/status/awaitingconfirmation`)
        ));
      },
      cancelEdit(companyId, tripId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/${tripId}/status/booked`)
        ));
      },
      getEmployee(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/employees`)
        ));
      },
      getEmployeesWithData(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/employees/data`)
        ));
      },
      getCompanies(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/companies`)
        ));
      },
      getProjects(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/projects`)
        ));
      },
      // аналитика переходов к поездке и изменений поездки
      sendTripRequestAnalytic([TripData]) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.PRODUCT_ANALYTICS.SEND}`, [TripData])
        ));
      },
      getUsers(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/approvedUsers`)
        ));
      },
      // сохранение изменения поездки
      save(companyId, orderTrip, orderId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/${orderId}/save/`, orderTrip)
        ));
      },
      sendDocumentsAfterSaveMetadata(accountId, tripId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${accountId}/SendDocuments/${tripId}/uac`)
        ));
      },
      // перерасчет стоимости поездки, при изменении самой поездки
      recalculateTripAmount(providerName, orderId, payload) {
        const token = self.currentToken;
        const request = (ctoken) => (
          xhr.sendJSON(ctoken, `${CONFIG.API_CLUSTER}/hotel-service/order/info/new/${providerName}/${orderId}`, payload)
        );

        if (token) {
          return request(token);
        }

        return self.requestWithFreshToken(() => request(self.currentToken));
      },
      // создание новой поездки
      create(companyId, orderTrip, email) {
        const tail = email.trim() ? `/email?email=${email}` : '';

        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/add${tail}`, orderTrip)
        ));
      },

      createApplicationEvent(formData) {
        const token = self.currentToken;
        const request = (ctoken) => (
          xhr.sendJSON(ctoken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EVENTS.CREATE_APPLICATION}`, formData)
        );

        if (token) {
          return request(token);
        }

        return self.requestWithFreshToken(() => request(self.currentToken));
      },

      cancelTrain(companyId, orderTripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/${orderTripItemId}/CancelRailwayTicket`)
        ));
      },
      manualTrainCancel(companyId, tripId, body) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/trips/${tripId}/ManualCancelRailwayTicket`,
            body,
          )
        ));
      },
      // обновить поездку в кэше
      clearCacheByTripId(companyId, tripId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/${tripId}/cache`)
        ));
      },
      countTrips() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/trips/count`));
      },
      addVatVersions(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.ADD_VAT_VERSIONS}`, data)
        ));
      },
      autocomplete(params) {
        const request = (cToken) => {
          if (params.ids) {
            return xhr.getJSON(cToken, `${CONFIG.APIW_ROOT}/transfers/autocomplete/${params.ids}/${params.query}`);
          }

          return xhr.getJSON(cToken, `${CONFIG.APIW_ROOT}/transfers/autocomplete/${params.query}`);
        };

        return self.requestWithFreshToken(request);
      },

      autocompleteHotel(params) {
        const request = (cToken) => {
          if (params.ids) return xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/autocomplete-hotels/${params.query}`);

          return xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/autocomplete-hotels/${params.query}`);
        };

        return self.requestWithFreshToken(request);
      },

      autocompleteRegion(value) {
        const request = (cToken) => (
          xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/autocomplete-regions/${value}`)
        );

        return self.requestWithFreshToken(request);
      },

      autocompleteCountry(str) {
        const request = (cToken) => (
          xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.AUTOCOMPLETE_COUNTRY(str)}`)
        );

        return self.requestWithFreshToken(request);
      },

      saveHotelBookRequest(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.HOTEL_BOOK_REQUEST}`, data)
        ));
      },

      saveEditedHotelBookRequest(data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.HOTEL_BOOK_REQUEST}`, data)
        ));
      },

      sendRequestToHotel(requestId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.SEND_REQUEST_TO_HOTEL(requestId)}`)
        ));
      },

      deleteSavedRequest(requestId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.DELETE_SAVED_REQUEST(requestId)}`)
        ));
      },

      getSavedRequest(requestId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.GET_SAVED_REQUEST(requestId)}`)
        ));
      },

      getHotelSavedRequests(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.GET_SAVED_BOOK_REQUESTS(companyId)}`)
        ));
      },

      sendCustomTemplate(requestId, file) {
        return self.requestWithFreshToken((token) => xhr.sendFile(
          token,
          `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.SEND_CUSTOM_TEMPLATE_REQUEST(requestId)}`,
          file,
        ));
      },

      getTypesCohortAanda() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/enums-service/hotels/types-cohort-aanda`)
        ));
      },

      gethotelStatic(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/static/${id}`)
        ));
      },

      getRegion(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/regions/${id}`)
        ));
      },
      // получить данные по контракту для отеля
      getContractHotel(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.CONTRACT.GET_CONTRACT(id)}`)
        ));
      },
      // удаление контракта для отеля
      deleteContractHotel(id) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.CONTRACT.DELETE_CONTRACT(id)}`)
        ));
      },
      // удаление контракта для отеля
      saveContractHotel(contract) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.CONTRACT.SAVE_CONTRACT}`, contract)
        ));
      },

      searchHotels(params) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/hotels/${params}`)
        ));
      },

      searchRegions(regionName) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/regions/search/${regionName}`)
        ));
      },

      updateHotel(hotel) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/update?withGiata=true`, hotel)
        ));
      },

      updateRegion(region) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/regions`, region)
        ));
      },

      addHotel(hotel) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/add/`, hotel)
        ));
      },

      addRegion(region) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/regions`, region)
        ));
      },

      deleteHotel(id) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/${id}`)
        ));
      },

      restoreHotel(id) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/hotel-service-dictionary/${id}/restore?duplicatesCheck=true`)
        ));
      },

      getRateTypes() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service/room-types/`)
        ));
      },

      travellineProviderPlans() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/travelline-provider/plans`)
        ));
      },

      travellineProviderRates(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/travelline-provider/hotel/${id}/rates-plans`)
        ));
      },
      getOrderInfoTravelLine(orderId, providerName, isCustom) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(
            token,
            `${CONFIG.API_CLUSTER}/hotel-service/order/info/${providerName}/${orderId}?custom=${isCustom}`,
          )
        ));
      },

      saveTravellineRatesAndPlans(id, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/travelline-provider/hotel/${id}/rates-plans/update `, data)
        ));
      },

      travellineHotelConnected(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/travelline-provider/hotels/connected`, data)
        ));
      },

      hotelCompare(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-compare/hotel/${id}`)
        ));
      },

      autocompleteCompany(params) {
        const request = (cToken) => {
          if (params.ids) return xhr.getJSON(cToken, `${CONFIG.API_ROOT}/companies/autocomplete?query=${params.query}`);

          return xhr.getJSON(cToken, `${CONFIG.API_ROOT}/companies/autocomplete?query=${params.query}`);
        };

        return self.requestWithFreshToken(request);
      },
      autocompleteTrainStation(params) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/autocomplete-trains/${params.query}`)
        ));
      },
      autocompleteBusStation(params) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/autocomplete-bus/${params.query}`)
        ));
      },
      autocompleteEmployees(companyId, body) {
        const token = self.currentToken;
        const request = (ctoken) => xhr.sendJSON(ctoken, `${CONFIG.API_LAYER}/employees/${companyId}/find`, body);

        if (token) {
          return request(token);
        }

        return self.requestWithFreshToken(() => request(self.currentToken));
      },

      autocompleteProvider(data, service) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/enums-service/providers/${service}/${data}`)
        ));
      },

      selectAndInputAllProviders(service) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/enums-service/providers/all/${service}`)
        ));
      },

      autocompleteTypesCustom() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/enums-service/custom/types`)
        ));
      },

      // получение сотрудника после автокомплита
      getEmployeeDataByID(id) {
        const token = self.currentToken;
        const request = (ctoken) => xhr.getJSON(ctoken, `${CONFIG.API_LAYER}/employees/${id}`);

        if (token) {
          return request(token);
        }

        return self.requestWithFreshToken(() => request(self.currentToken));
      },
      // получение данных по гостинице
      getHotelDetails(Id) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/hotels/${Id}/details`));
      },
      // сопоставление отелей
      checkMatchHotels(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.CHECK_HOTELS_MATCH}`, data)
        ));
      },
      // сопоставление на странице создания/редактирования
      comparesCheck(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.COMPARES_CHECK}`, data)
        ));
      },
      // получить сопоставления giata по id
      getHotelsByGiataIds(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.GET_HOTELS_BY_GIATA_IDS}`, data)
        ));
      },
      // удалить заказа с методанными
      deleteMetadata(tripId, tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/trips/${tripId}/items/${tripItemId}/delete`)
        ));
      },
      // получение суммы возврата ж/д билета
      getCancellationFee(companyId, tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/${tripItemId}/refundSum`)
        ));
      },
      // получение суммы возврата авиаэкспреса
      getRefundSumAeroExpress(accountId, tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/${tripItemId}/refundAeroexpress`)
        ));
      },
      // возврат авиаэкспреса
      setCancellationAeroExpress(accountId, tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/${tripItemId}/cancelAeroexpress`)
        ));
      },
      // получение суммы возврата авиабилета
      getRefundSumAirline(accountId, tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/${tripItemId}/airline/refund/sum`)
        ));
      },
      // получение статуса авиабилета из БД
      getTicketStatus(tripItemId) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_CLUSTER}/flights/${tripItemId}`));
      },
      // обновление статуса авиабилета из БД
      updateTicketStatus(tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/flights/status/update/manually`, tripItemId)
        ));
      },
      // возврат авиабилета
      setCancellationAirlineFee(accountId, tripItemId, penalties) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token, `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/${tripItemId}/airline/refund`, penalties,
          )
        ));
      },
      // скачать ваучер
      downloadVoucher(companyId, tripItemId, locale) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/trips/items/${tripItemId}/voucher?locale=${locale}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать ваучер по групповому проживанию
      downloadVoucherGroupLiving(companyId, tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}${ROUTES.CUSTOM.DOWNLOAD_VOUCHER_GROUP(companyId, tripItemId)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать ваучер без завтрака
      downloadBreakfastVoucher(companyId, tripItemId, body) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/trips/items/${tripItemId}/voucher/options`,
            `${CONFIG.API_CLUSTER}/files/file`,
            body,
          )
        ));
      },
      // скачать старую версию ваучера
      downloadOldVoucher(accountId, tripItemVersionId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/versions/${tripItemVersionId}/voucher`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать ваучер для дополнительной услуги (АБ места)
      downloadServiceForm(accountId, tripItemId, serviceId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/${tripItemId}/additional-services/${serviceId}/voucher`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // обновить ваучер жд
      changeTrainVoucher(tripItemData) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/train-service/order/blank`, tripItemData)
        ));
      },
      // обновить ваучер аэро
      changeAeroexpressVoucher(tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.AEROEXPRESS.CHANGE_AEROEXPRESS_VOUCHER(tripItemId)}`)
        ));
      },
      // получить ваучер от поставщика аэро
      getAeroexpressVoucher(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/aeroexpress/update-blanks`, data)
        ));
      },
      // запостить кастомный ваучер аэро
      postAeroexpressVoucher(serviceType, file) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(token, `${CONFIG.API_CLUSTER}${ROUTES.AEROEXPRESS.POST_AEROEXPRESS_VOUCHER(serviceType)}`, file)
        ));
      },
      // Изменение ЦЗ
      сhangeСostСenter(objChangeCC) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/trips/change-project`, objChangeCC)
        ));
      },
      // добавление поездки в ваучер
      addTripToVoucher(tripItemVersionId, voucherId, newTripId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token, `${CONFIG.API_ROOT}/trip/${tripItemVersionId}/taxi/${voucherId}/operations?orderId=${newTripId}`,
          )
        ));
      },
      // проверка перед удалением поездки, заказов или последней версии
      check(tripId, orderIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/trips/${tripId}/check`, { TripItemIds: orderIds })
        ));
      },
      // проверка перед переносом поездок
      checkTrips(tripIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TRIP.CHECK_TRIPS}`, { TripIds: tripIds })
        ));
      },
      // перенос поездок
      moveTrips(tripIds, companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TRIP.MOVE_TRIPS(companyId)}`, { TripIds: tripIds })
        ));
      },
      // удаление или перемещение поездки
      moveTrip(tripId, companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/trips/${tripId}/companies/${companyId}/move`)
        ));
      },
      // удаление или перемещение заказа
      moveOrders(tripId, companyId, orderIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token, `${CONFIG.API_ROOT}/trips/${tripId}/companies/${companyId}/items/move`, { TripItemIds: orderIds },
          )
        ));
      },
      // удаление поездки из ваучера такси
      moveOperations(tripId, voucherId, operationIds) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_ROOT}/trip/${tripId}/taxi/${voucherId}/operations`, operationIds)
        ));
      },
      // удаление или перемещение последней версии
      moveActualVersion(tripId, companyId, orderId, tripItemVersionId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/trips/${tripId}/companies/${companyId}/items/${orderId}/versions/${tripItemVersionId}/move`,
          )
        ));
      },
      // перемещение поездок и ваучеров такси
      moveVouchers(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/taxi-service/replacing/voucher`, data)
        ));
      },
      // создание подарочного ваучера такси
      saveGiftVoucher(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/taxi-service/loyalty-voucher`, data)
        ));
      },
      // отправка ваучеров на почту
      sendVouchers(accountId, tripId, email) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${accountId}/trips/${tripId}/email?email=${email}`)
        ));
      },
      // отправка ваучеров для дополнительной услуги на почту
      sendServiceForm(accountId, tripItemId, serviceId, email) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/${tripItemId}/additional-services/${serviceId}/send?email=${email}`,
          )
        ));
      },
      // отправка ваучера на почту
      sendVoucher(companyId, itemId, emails, locale) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/items/${itemId}/email?locale=${locale}`, emails,
          )
        ));
      },
      // отправка старой версии ваучера на почту
      sendOldVoucher(accountId, tripItemVersionId, emails) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token, `${CONFIG.API_ROOT}/companies/${accountId}/trips/items/versions/${tripItemVersionId}/email`, emails,
          )
        ));
      },
      // отправка счета на почту
      sendInvoice(accountId, tripId, email) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${accountId}/Invoices/${tripId}/email?email=${email}`)
        ));
      },
      getOrderHotelInfo(provider, orderId = 0) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service/order/info/${provider}/${orderId}`)
        ));
      },
      getExchangeRates(currency) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EXCHANGE_CURRENCY(currency)}`)
        ));
      },
      cancelCurrencyAmount(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.CANCEL_CURRENCY_AMOUNT}`, data)
        ));
      },
      getPricesBookingMarkup(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.BOOKING_MARKUP}`, data)
        ));
      },
      getOrderTrainInfo(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/train-service/order/info`, data)
        ));
      },
      getOrderTransferInfo(orderId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/transfer-service/order`, { OrderId: orderId, TransactionId: 0 })
        ));
      },
      loadAirlineChangeRate(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.AIRLINE.CHANGE_RATE(accountId)}`)
        ));
      },
      loadTrainChangeRate(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.TRAIN.CHANGE_RATE(accountId)}`)
        ));
      },
      loadBusChangeRate(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BUS.CHANGE_RATE(accountId)}`)
        ));
      },
      loadIntegrationVersionsForAllCompanies(tripId) {
        if (tripId) {
          return self.requestWithFreshToken((token) => (
            xhr.getJSON(token, `${CONFIG.API_ROOT}/integration/1C/trip/${tripId}/versions/`)
          ));
        }

        return [];
      },
      // принудительная отправка версии заказа для интеграции с 1С
      sendVersionOfIntegration(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/integration/1C/version`, data)
        ));
      },
      // скачивания xml версии заказа для интеграции с 1С
      downloadVersionOfIntegration(guid) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token, `${CONFIG.API_ROOT}/integration/1C/package/${guid}`, `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // получение MandatoryProject(компании с обязательными центрами затрат)
      getMandatoryProject(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/access-settings/company/mandatory-project/${accountId}`)
        ));
      },

      getTrainCarriers() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/train/carriers`));
      },

      sendTrainVoucher(file) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(token, `${CONFIG.API_ROOT}/trainVoucher/upload`, file)
        ));
      },

      sendCustomVoucher(files) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFiles(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.SEND_CUSTOM_VOUCHER}`, files)
        ));
      },
      sendAirAdditionalVoucher(files) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFiles(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.SEND_AIRLINE_ADDITIONAL_VOUCHER}`, files)
        ));
      },
      sendCustomVoucher2(files) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.SEND_CUSTOM_VOUCHER}`, files)
        ));
      },
      sendBusVoucher(files) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFiles(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.SEND_BUS_VOUCHER}`, files)
        ));
      },
      // скачать одиночную справку
      getFlightCertificate(airItemId, signatureType) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_FLIGHT_CERTIFICATE(airItemId, signatureType)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getFreeFlightCertificate(airItemId, signatureType) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_FREE_FLIGHT_CERTIFICATE(airItemId, signatureType)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getStatusFlightCertificate(airItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_STATUS_FLIGHT_CERTIFICATE(airItemId)}`)
        ));
      },
      bookFlightCertificate(airItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.TRIP.BOOK_FLIGHT_CERTIFICATE(airItemId)}`)
        ));
      },
      getTripInfo(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/airline-service/ticket/info`, data)
        ));
      },
      sendVoidingInfo(accountId, tripId, tripItemId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${accountId}/trip/${tripId}/${tripItemId}/voiding`)
        ));
      },
      getFile(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getPDFFile(token, `${CONFIG.API_ROOT}${ROUTES.TRIP.GET_FILE(id)}`)
        ));
      },
      getTripComments(tripId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_COMMENTS(tripId)}`)
        ));
      },
      /** изменение номера авиабилета  */
      changeAirlaneTicket(versionId, ticketNumber) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EDIT_AIRLANES_TICKET_NUMBER(versionId)}`, ticketNumber)
        ));
      },
      /** получение списка закрывающих документов для закрытого периода */
      getAirlaneTicketClosing(versionId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_AIRLANES_TICKET_CLOSING(versionId)}`)
        ));
      },
      getTaxiVoucher(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_TAXI_VOUCHER(id)}`)
        ));
      },
      getVatAmount(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_VAT_AMOUNT}`, data)
        ));
      },
      getValidation(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_VALIDATION}`, data)
        ));
      },
      sendVersionTime(data, eventDate) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSONPatch(token, `${CONFIG.API_ROOT}${ROUTES.TRIP.SEND_VERSION_TIME(data)}`, eventDate)
        ));
      },
      getTripInfoById(id, accountId) {
        return self.requestWithFreshToken(
          (token) => xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_TRIP_INFO_BY_ID(id, accountId)}`),
        );
      },
      postMergeTrips(sourceId, trip) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(
          token,
          `${CONFIG.API_CLUSTER}${ROUTES.TRIP.MERGE_TRIPS(sourceId)}`,
          trip,
        ));
      },
      getApplicationEvents(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EVENTS.GET_APPLICATION}`, data)
        ));
      },
      // удалить заявку на мероприятие
      deleteApplicationEvent(id) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EVENTS.DELETE_APPLICATION(id)}`)
        ));
      },
      // изменить статус заявки на мероприятие - на "Отменена"
      cancelApplicationEvents(id) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EVENTS.CANCEL_APPLICATION(id)}`)
        ));
      },
      // изменить заявку на мероприятие в поездке
      setApplicationEvents(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EVENTS.SET_APPLICATION}`, data)
        ));
      },
      // получить информацию о согласовании согласующими
      downloadTripApprovalReport(tripId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.TRIP.DOWNLOAD_TRIP_APPROVAL_REPORT(tripId)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // открепить заявку на мероприятие от поездки
      unpinApplicationEvents(id) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.EVENTS.UNPIN_APPLICATION(id)}`)
        ));
      },
      // получить смету по поездке
      getEstimate(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.ESTIMATE(id)}`)
        ));
      },
      // изменить смету по поездке
      setEstimate(id, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.ESTIMATE(id)}`, data)
        ));
      },
      getInsuranceCalculate(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.INSURANCE.GET_ITEMS(id)}`)
        ));
      },
      recalculateRatesInsurance(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.INSURANCE.RECALCULATE_RATES}`, data)
        ));
      },
      getEmployeesByIds(ids) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/employees`, ids));
      },
      // получение деталей операции по versionId
      getOperationsByVersionIds(versionIds) {
        return self.requestWithFreshToken((token) => xhr
          .sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_OPERATIONS}`, versionIds));
      },
      // обновление цен для версий
      updatePrice(versionId, data) {
        return self.requestWithFreshToken((token) => xhr
          .putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.UPDATE_PRICE(versionId)}`, data));
      },
    };
  }

  get Account() {
    const self = this;

    return {
      getAccount(accountId) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}`));
      },
      getAccountSettings(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/access-settings/accounts/${accountId}`)
        ));
      },
      updateAccountSettings(accountSettings) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/access-settings/accounts`, accountSettings)
        ));
      },
      getSpecialBill(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/special-bill-service/${accountId}`)
        ));
      },
      accountAutocomplete(query) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/autocomplete`, query)
        ));
      },
      addAccountToCompaniesGroup(AccountId, MainAccountId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/group`, { AccountId, MainAccountId })
        ));
      },
      deleteAccountFromCompaniesGroup(AccountId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/${AccountId}/group`)
        ));
      },
      getInfoAboutCompaniesGroup(AccountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/${AccountId}/group`)
        ));
      },
      getAccountSupportsList() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/account-supports`)
        ));
      },
      getCompaniesInAccount(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service/companies/${accountId}`)
        ));
      },
      updateAccount(info) {
        return self.requestWithFreshToken((token) => xhr.putJSON(token, `${CONFIG.API_ROOT}/account`, info));
      },
      updateSpecialBill(info) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/special-bill-service`, info)
        ));
      },
      deleteSpecialBill(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/special-bill-service/${accountId}/disable`)
        ));
      },
      // TODO: проверить, что метод действительно не используется и удалить
      getCompanies(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/companies`)
        ));
      },
      addCompany(accountId, company) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/companies/add`, company)
        ));
      },
      getHotelRates(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotelrate/actualrate?accountId=${accountId}`)
        ));
      },
      getAirlineRates() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/rates/airline`));
      },
      getTrainRates() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/rates/train`));
      },
      getBusRates() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BUS.LOAD_RATE}`)
        ));
      },
      updateAirlineRate(accountId, body) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/airlineRate`, body)
        ));
      },
      updateTrainRate(accountId, body) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/trainRate`, body)
        ));
      },
      updateHotelRate(accountId, value) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/hotelrate/rate/${accountId}/${value}`)
        ));
      },
      updateBusRate(value) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BUS.LOAD_RATE}`, value)
        ));
      },
      updatePlanFeeSettings(data) {
        return self.requestWithFreshToken((token) => xhr.putJSON(token, `${CONFIG.API_ROOT}/planfee/settings`, data));
      },
      deletePlanFeeSettings(id) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_ROOT}/planfee/settings`, `${id}`)
        ));
      },
      downloadSalesReport(accountId, startDate, endDate, type) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/sales/${accountId}/${startDate}/${endDate}/${type}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // заблокировать аккаунт
      accountDisabled(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/switch-enabled-status `, accountId)
        ));
      },
      getAmoId(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/amocrm/link?MultiCompanyId=${accountId}`)
        ));
      },

      getAggregation(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/${accountId}/aggregation`)
        ));
      },
      getAggregationAirline(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/airline-aggregation/aeroflotStatement/${accountId}`)
        ));
      },
      updateAggregation(accountId, aggregationId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/${accountId}/aggregation/${aggregationId}`)
        ));
      },
      deleteAggregation(accountId, aggregationId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/${accountId}/aggregation/${aggregationId}`)
        ));
      },
      getExtendedInfo(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/${accountId}/sell-info`)
        ));
      },
      updateExtendedInfo(accountId, info) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/${accountId}/sell-info/update`, info)
        ));
      },
      getSettingAggregation(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-aggregation/accounts/${accountId}/settings`)
        ));
      },
      getTypes() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/enums-service/hotels/types`)
        ));
      },
      updateSettingAggregation(info) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/hotel-aggregation/accounts/settings`, info)
        ));
      },
      getSmartAgent(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.ACCOUNT.GET_SMART_AGENT(accountId)}`)
        ));
      },
      getSmartAgentAuto(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.ACCOUNT.GET_SMART_AGENT_AUTO(accountId)}`)
        ));
      },
      getSmartwayKZ(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.ACCOUNT.GET_SMARTWAY_KZ(accountId)}`)
        ));
      },
      saveLeadType(leadInfo) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.ACCOUNT.SAVE_LEAD_TYPE}`, leadInfo)
        ));
      },
      getLeadType(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.ACCOUNT.GET_LEAD_TYPE(accountId)}`)
        ));
      },
      deleteLeadType(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.ACCOUNT.DELETE_LEAD_TYPE(accountId)}`)
        ));
      },
    };
  }

  get Company() {
    const self = this;

    return {
      getDetails(companyId) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}`));
      },
      // Получение настроек компании
      getCompanySettings(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/access-settings/company/${companyId}`)
        ));
      },
      // Изменение настроек компании
      updateCompanySettings(companySettings) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/access-settings/company`, companySettings)
        ));
      },
      getApplications1CFF(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/feature-flags/account/${accountId}/flags/users`)
        ));
      },
      updateApplications1CFF(accountId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/feature-flags/account/${accountId}/flags/users-update`, data)
        ));
      },
      // Получение реквизитов компании
      getRequisites(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service-documents/invoice-requisites/${companyId}`)
        ));
      },
      // Обновление реквизитов компаниее
      updateRequisites(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service-documents/invoice-requisites/`, data)
        ));
      },
      // Удаление реквизитов компании
      updateRequisitesDefault(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}/account-service-documents/invoice-requisites/${companyId}`)
        ));
      },
      // Получение настроек пользователя
      getUserSettings(accountId, userId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/access-settings/users?accountId=${accountId}&userId=${userId}`)
        ));
      },
      // Изменение настроек пользователя
      updateUserSettings(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/access-settings/users`, data)
        ));
      },
      // Получение prohibitionVouchers
      getProhibitionVouchers(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/access-settings/company/prohibition-vouchers/${accountId}`)
        ));
      },
      // Удаление почты пользователя
      getDisabledUserSettings(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/user-service/user/free-user-email/${userId}`)
        ));
      },
      removeCompany(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/remove/${companyId}`)
        ));
      },
      // сохранение реквизитов компании
      saveDetails(companyId, details) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}`, details)
        ));
      },
      // изменение/сохранение авиаконтракта компании
      saveAviaContract(companyId, aviaContract) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/airline-service/contract/company/${companyId}`, aviaContract)
        ));
      },
      // удаление авиаконтракта компании
      removeAviaContract(companyId, aviaContract) {
        return self.requestWithFreshToken((token) => (
          xhr.delDataJSON(token, `${CONFIG.API_CLUSTER}/airline-service/contract/company/${companyId}`, aviaContract)
        ));
      },
      // получение авиаконтракта компании
      getAviaContract(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/airline-service/contract/company/${companyId}`)
        ));
      },
      getHotelContract(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotels3d/list/${companyId}`)
        ));
      },
      getHotelContractId(contractId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotels3d/get/${contractId}`)
        ));
      },
      getRatesFromAanda(hotelId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service/hotel/aanda/${hotelId}/rates/specification`)
        ));
      },
      saveHotelContract(hotelContract) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/hotels3d/save`, hotelContract)
        ));
      },
      updateHotelContract(hotelContract) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/hotels3d/update/`, hotelContract)
        ));
      },
      deleteHotelContract(contractId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotels3d/delete/${contractId}`)
        ));
      },
      uploadHotelContractFile(file) {
        return self.requestWithFreshToken((token) => xhr.sendFile(token, `${CONFIG.API_CLUSTER}/hotels3d/file/`, file));
      },
      downloadHotelContract(fileId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithToken(token, `${CONFIG.API_CLUSTER}/hotels3d/file/${fileId}`)
        ));
      },
      checkHotelBooking(hotelId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/booking-provider/booking-id/${hotelId}`)
        ));
      },
      updateDisplayDateInDocuments(companyId, show) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/contract/settings`, show)
        ));
      },
      getSettingsDisplayDate(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/contract/settings`)
        ));
      },
      // получение информации берется ли комиссия за оплату картой
      getCardPaymentFee(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.COMPANIES.CARD_PAYMENT_FEE(companyId)}`)
        ));
      },
      // обновление информации берется ли комиссия за оплату картой
      updateCardPaymentFee(companyId, cardPaymentFee) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}${ROUTES.COMPANIES.CARD_PAYMENT_FEE(companyId)}`, cardPaymentFee)
        ));
      },
      // получить схему налогооблажения в аккаунте с признаком смартагента
      getTaxShemasSA(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.COMPANIES.GET_TAX_SHEMAS_SA(companyId)}`)
        ));
      },
      // изменить схему налооблажения в аккаунте с признаком смартагента
      setTaxShemasSA(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.COMPANIES.SET_TAX_SHEMAS_SA}`, data)
        ));
      },
      getRoomType() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/hotel-service/room-types/`)
        ));
      },
      // получение операций по Id компании
      getOperations(companyId, startDate = '', endDate = '') {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/companies/${companyId}/operations?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`,
          )
        ));
      },
      // получение финансов по Id компании
      getFinance(companyId, startDate = '', endDate = '') {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/companies/${companyId}/finance?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`,
          )
        ));
      },
      getOrders(companyId, count = 30) { // значение count по умолчанию определяет количество заргужаемых поездок в первый раз
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips?count=${count}`)
        ));
      },
      getDepartments(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/departments`)
        ));
      },
      getProjects(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/projects`)
        ));
      },
      getTags(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/tags`)
        ));
      },
      addTransaction(companyId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/operations/Incoming`, data)
        ));
      },
      addCardTransaction(companyId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/operations/card`, data)
        ));
      },
      addDeductionAvia(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/plan-fee-service/flight-certificate-fee/manual`, data)
        ));
      },
      returnTransaction(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/operations/outgoing`, data)
        ));
      },
      saveTransaction(companyId, operationId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/operations/${operationId}`, data)
        ));
      },
      // Изменение АП
      savePlanFeeTransaction(data) {
        return self.requestWithFreshToken((token) => xhr.putJSON(token, `${CONFIG.API_ROOT}/planfee/operations`, data));
      },
      // Удаление операции
      deleteTransaction(companyId, operationId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/operations/${operationId}`)
        ));
      },
      // Удаление операции АП
      deletePlanFeeTransaction(companyId, operationId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_ROOT}/planfee/companies/${companyId}/operations/${operationId}`)
        ));
      },
      // Проверка в закрывающих перед удалением
      checkOperationInClosingDocuments(operationId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/planfee/operations/${operationId}/closing`)
        ));
      },
      editTransactionDetails(companyId, operationId, operationDetailsId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/operations/${operationId}/details/${operationDetailsId}`,
            data,
          )
        ));
      },
      getUsers(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/users`)
        ));
      },
      saveUser(id, data) {
        return self.requestWithFreshToken((token) => xhr.putJSON(token, `${CONFIG.API_ROOT}/user/${id}`, data));
      },
      editUserRightsAdmin(id) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/user/${id}/admin`));
      },
      editUserRightsDisabled(data) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/user/disable`, data));
      },
      getUserApproveScheme(id) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/approver/${id}/state`));
      },
      getEmployees(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/employees`)
        ));
      },
      getEmployee(employeeId) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/employees/${employeeId}`));
      },
      // обновить поездки компании в кэше
      clearCacheByCompanyId(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trips/cache`)
        ));
      },
      // скачать штраф
      downloadPenalty(companyId, invoiceNumber, format) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/penaltyInvoice/${invoiceNumber}/${format}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать счет
      downloadInvoice(companyId, invoiceNumber, format, stamp) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/invoice/${invoiceNumber}/${format}?signature=${stamp}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать счет без слеша
      downloadInvoiceWithoutSlash(companyId, invoiceNumber, format) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/invoice/${invoiceNumber}/empty/${format}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать сводный счет компании
      getInvoicePaymentTotal({
        companyId, startDateValue = '', endDateValue = '', format, flagStamp,
      }) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/companies/${companyId}/invoice/total/${format}?startDate=${encodeURIComponent(startDateValue)}&endDate=${encodeURIComponent(endDateValue)}&signature=${flagStamp}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать акт сверки компании
      getRevise(companyId, format, startDate = '', endDate = '', isDetailed, companiesIds, type, signature) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token, `${CONFIG.API_ROOT}/report/${companyId}/reviseAct/${format}`, `${CONFIG.API_CLUSTER}/files/file`, {
              startDate, endDate, isDetailed, companiesIds, type, signature,
            },
          )
        ));
      },
      // Отправить в ЭДО
      sendToEDO(companyId, format, startDate = '', endDate = '', isDetailed, companiesIds, type, signature) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/report/${companyId}/reviseAct/${format}/diadoc/send`, {
            startDate, endDate, isDetailed, companiesIds, type, signature,
          })
        ));
      },
      // скачать файл с расхождениями по акту сверки
      getDiscrepancyFile(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/report/${companyId}/reviseAct/find-discrepancy/report/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать счет на оплату
      getPrepayInvoice(companyId, format, sum, flagStamp) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/deposit/${format}?sum=${sum}&signature=${flagStamp}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // скачать единую справку по всем перелетам
      getCertificateByEveryFlight(data, format) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token,
            `${CONFIG.API_CLUSTER}/booking-service-documents/certificates/download/all/table/${format}`,
            `${CONFIG.API_CLUSTER}/files/file`,
            data,
          )
        ));
      },
      // скачать отдельную справку на каждый перелет
      getCertificateBySingleFlight(data, format) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token,
            `${CONFIG.API_CLUSTER}/booking-service-documents/certificates/download/all/${format}`,
            `${CONFIG.API_CLUSTER}/files/file`,
            data,
          )
        ));
      },
      // получить баланс компании (дебет и кредит)
      getBalance(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/balance`)
        ));
      },
      // получить счет компании
      getBilling(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/account/billing`)
        ));
      },
      // получить информацию о финансах компании
      getFunds(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/account/funds`)
        ));
      },
      // получить все признаки подаккаунта
      getAccountType() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/company-types`));
      },
      // период выставления УПД
      getPeriodUpd(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/updPeriodsPlan`)
        ));
      },
      getSchemas(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/reporting/companies/${accountId}/schemas`)
        ));
      },
      // изменение периода отчетности компании
      updateReportingPeriod(companyId, period) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/period?period=${period}`)
        ));
      },

      updateReportingSendingType(companyId, sending) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/sending?sending=${sending}`)
        ));
      },
      // изменение схемы отчетности компании
      updateReportingSchema(companyId, schema) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/schema?schema=${schema}`)
        ));
      },
      createPackages(companyId, monthCount) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/packages?futureMonth=${monthCount}&create=true`)
        ));
      },
      updateDocument(companyId, packageId, documents) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/packages/${packageId}/items`, documents)
        ));
      },

      getFormedReviseFlag(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/revise/settings`)
        ));
      },

      formedReviseFlag(companyId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/revise/settings`, data)
        ));
      },

      // получить закрывающий документ
      getDocument(documentId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/closing/items/get`, documentId)
        ));
      },
      // удалить закрывающий документ
      deleteDocument(companyId, packageId, documentId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/packages/${packageId}/${documentId}`)
        ));
      },
      // получение деталей документов
      loadPackageChanges(packageIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/packages/changes/last`, packageIds)
        ));
      },
      // получение деталей документов
      loadPackageDetails(packageIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/packages/details`, packageIds)
        ));
      },
      // добавление деталей документов
      updatePackageDetails(packageDetails) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/packages/details`, [packageDetails])
        ));
      },
      // удаление деталей документов
      deletePackageDetails(packageId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_ROOT}/packages/details`, packageId)
        ));
      },
      // получение комментария по компании
      getCommentCompany(companyId, schema) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service-closing/comment/get/${companyId}/${schema}`)
        ));
      },
      // изменение комментария по компании
      editCommentCompany(packageComment) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/account-service-closing/comment/add`, packageComment)
        ));
      },
      // получение документов требующие корректрировок
      loadPackageDocumentsCorrection(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/packages/correction/1/${startDate}/${endDate}/`)
        ));
      },
      // получение данных по edo компании
      getPeriodEdo(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service/companies/edo`, [companyId])
        ));
      },
      // получить правила формирования закрывающих документов (для настройки формировать без ФИО)
      getRulesFormingClosing(companyId, schemaName) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service-closing/closingflags/${companyId}/${schemaName}`)
        ));
      },
      // изменить правила формирования закрывающих документов (для настройки формировать без ФИО)
      setRulesFormingClosing(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service-closing/closingflags`, data)
        ));
      },
      // выставление данных по edo компании
      updatePeriodEdo(CompanyId, SendingPeriod, EDO) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}/account-service/companies/edo`, [
            {
              CompanyId,
              EDO,
              SendingPeriod,
            },
          ])
        ));
      },
      // получение по признаку Период Отправки
      // loadPackageDocumentsSP(period) {
      // период
      // return self.requestWithFreshToken(token => xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service/companies/edo/period/${period}`));
      // },
      getTrimmedPackages(companyId, startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/trimmedpackages/${startDate}/${endDate}`)
        ));
      },
      downloadDocument(companyId, packageId, documentId, format) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/packages/${packageId}/document/${documentId}/${format}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      deletePackages(params) {
        return self.requestWithFreshToken((token) => xhr.delDataJSON(token, `${CONFIG.API_ROOT}/packages`, params));
      },
      updateOverdraft(companyId, overdraftCompany) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/billingAccount/overdraft`, overdraftCompany)
        ));
      },
      downloadEDM(companyId, ids) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token,
            `${CONFIG.API_ROOT}/companies/${companyId}/packages/archive`,
            `${CONFIG.API_CLUSTER}/files/file`,
            ids,
          )
        ));
      },
      downloadPackages(companyId, ids) {
        return self.requestWithFreshToken((token) => xhr.downloadWithGuid(token, `${CONFIG.API_ROOT}/companies/${companyId}/packages/documents/withouttickets?ids=[${ids.join(',')}]`, `${CONFIG.API_CLUSTER}/files/file`));
      },
      downloadVouchers(companyId, ids) {
        return self.requestWithFreshToken((token) => xhr.downloadWithGuid(token, `${CONFIG.API_ROOT}/companies/${companyId}/packages/documents/tickets?ids=[${ids.join(',')}]`, `${CONFIG.API_CLUSTER}/files/file`));
      },
      getInvoice(companyId, invoiceNum) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/invoice/${invoiceNum}/`)
        ));
      },
      saveInvoice(companyId, invoiceNum, item) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/invoice/${invoiceNum}/`, item)
        ));
      },
      // включение уведомлений пользователя о балансе и тд
      userNotificationOn(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/access-settings/users/default-notification/${userId}`)
        ));
      },
      // выключение уведомлений пользователя о балансе и тд
      userNotificationOff(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.delDataJSON(token, `${CONFIG.API_CLUSTER}/access-settings/users/default-notification/${userId}`)
        ));
      },
      saveNotificationBalance(companyId, balance) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(
            token, `${CONFIG.API_ROOT}/notifications/companies`, { CompanyId: companyId, LowBalance: balance },
          )
        ));
      },
      // получение информации по интеграции с 1С
      loadIntegration(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/integration/1C/${companyId}`)
        ));
      },
      // получение авансовых отчетов
      getExpenseReports(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/expense-report/reports?companyId=${companyId}`)
        ));
      },
      // удаление авансового отчета
      deleteExpenseReportItem(id) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}/expense-report/report/${id}`)
        ));
      },
      // изменение даты и времени интеграции с 1С
      saveIntegration(data) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/integration/1C`, data));
      },
      // изменение периода выгрузки авансовых отчетов
      changeUnloadingExpenseReports(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/expense-report/status/change`, data)
        ));
      },
      getDebts(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/account/debt`)
        ));
      },
      getCompanyDebt(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.COMPANIES.GET_COMPANY_DEBT(companyId)}`)
        ));
      },
      sendComment(companyId, comment = '') {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/comment`, { Comment: comment })
        ));
      },
      sendUserDocuments(data) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(token, `${CONFIG.API_ROOT}/user/documents`, data));
      },
      sendDiadoc(documentsPackageIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/diadoc/mailing`, documentsPackageIds)
        ));
      },
      // получение доступные средства для рядовых сотрудников
      getLimitAmount(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/billingAccount/visibleLimitAmount`)
        ));
      },
      // сохранение доступных средств для рядовых сотрудников
      saveLimitAmount(companyId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/billingAccount/visibleLimitAmount`, data)
        ));
      },
      saveUserComment(userId, comment) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_ROOT}/users/${userId}/comment`, `"${comment}"`)
        ));
      },
      addPlanFee(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.COMPANIES.ADD_PLAN_FEE}`, data)
        ));
      },
      getInsurance(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service-insurance/insurance/${companyId}`)
        ));
      },
      saveInsurance(insuranceData) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/account-service-insurance/insurance`, insuranceData)
        ));
      },
      sendXmlTicket(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/ones-service/get_xml/zip`, data)
        ));
      },
      // Отправка билетов в 1С
      sendXmlTickets(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/integration/1C/set-upload-versions`, data)
        ));
      },
      downloadXmlTicket(guid) {
        return self.requestWithFreshToken(() => xhr.downloadJSON(`${CONFIG.API_CLUSTER}/files/file`, guid));
      },
      downloadAnalytics(data) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token, `${CONFIG.API_ROOT}/analytics/reports/basic-trip/`, `${CONFIG.API_CLUSTER}/files/file`, data,
          )
        ));
      },
      getStatisticFunctional(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_LAYER}${ROUTES.STATISTIC.FUNCTIONAL(accountId)}`)
        ));
      },
      // Выгрузка сотрудников
      unloadEmployees(CompanyId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.UPLOAD_SERVICES.UNLOAD_EMPLOYEES(CompanyId)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // Обработать файл массовой загрузки сотрудников
      sendUploadEmployeeFile(File, CompanyId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(
            token, `${CONFIG.API_CLUSTER}${ROUTES.UPLOAD_SERVICES.SEND_UPLOAD_EMPLOYEE_FILE(CompanyId)}`, File,
          )
        ));
      },
      // Подтвердить массовую зугрузку сотрудников
      aproveDownloadEmployees(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.UPLOAD_SERVICES.APPROVE_DOWNLOAD_EMPLOYEES}`, data)
        ));
      },
      // Загрузить файл "массовая загрузка сотрудников с тп и цз"
      sendBulkUploadFile(File, AccountId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(token, `${CONFIG.API_CLUSTER}${ROUTES.UPLOAD_SERVICES.SEND_BULK_UPLOAD_FILE(AccountId)}`, File)
        ));
      },
      // Скачать шаблон для массовой загрузки сотрудников xlsx
      downloadPattern(type) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.UPLOAD_SERVICES.DOWNLOAD_PATTERN(type)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // Скачать шаблов прав сотрудников для массовой загрузки xlsx
      downloadPatternRights(type, companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.UPLOAD_SERVICES.DOWNLOAD_PATTERN_RIGHTS(type, companyId)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // Сценарий массовой загрузки: загрузка прав сотрудников
      downloadRightsEmployees(File, AccountId, companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.UPLOAD_SERVICES.DOWNLOAD_RIGHTS_EMPLOYEES(AccountId, companyId)}`,
            File,
          )
        ));
      },
      getStatisticComplaints(dataComplaints) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.STATISTIC.COMPLAINTS}`, dataComplaints)
        ));
      },
      createStatisticComplaint(dataComplaints) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.STATISTIC.COMPLAINTS}`, dataComplaints)
        ));
      },
      updateStatisticComplaint(dataComplaints) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.STATISTIC.COMPLAINTS}`, dataComplaints)
        ));
      },
      removeStatisticComplaint(dataComplaints) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.STATISTIC.COMPLAINTS}`, dataComplaints)
        ));
      },
      updateStatisticBonuses(accountId, dataBonuses) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.STATISTIC.SAVE_BONUSES(accountId)}`, dataBonuses)
        ));
      },
      getPersonalTransactions(filters) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.PERSONAL_TRANSACTION.GET_TRANSACTIONS}`,
            filters,
          )
        ));
      },
      sendReceiptToEmail(receiptId, email) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.PERSONAL_TRANSACTION.SEND_RECEIPT_TO_EMAIL(receiptId)}`,
            { email },
          )
        ));
      },
      loadListRegistryTemplates(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.LIST(companyId)}`)
        ));
      },
      uploadRegistryTemplates(companyId, file) {
        return self.requestWithFreshToken((token) => (
          xhr.sendFile(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.UPLOAD(companyId)}`,
            file,
          )
        ));
      },
      updateRegistryTemplatesName(companyId, registryTemplateId, name) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.UPDATE_NAME(companyId)}`,
            { registryTemplateId, name },
          )
        ));
      },
      downloadRegistry(id, format) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.BUILD(id, format)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadTemplate(registryTemplateId) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.GET_TEMPLATE(registryTemplateId)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      removeRegistryTemplates(ids) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.REMOVE}`, ids)
        ));
      },
      loadRegistries(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.LOAD_REGISTRIES(companyId)}`)
        ));
      },
      addRegistry(companyId, registry) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.ADD(companyId)}`, registry)
        ));
      },
      updateRegistryDate(constructedRegistryId, date, companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.UPDATE_DATE(
              constructedRegistryId,
              date,
              companyId,
            )}`,
          )
        ));
      },
      updateRegistryFileType(constructedRegistryId, fileTypes) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.UPDATE_FILETYPE(constructedRegistryId)}`,
            fileTypes,
          )
        ));
      },
      removeRegistry(constructedRegistryId) {
        return self.requestWithFreshToken((token) => (
          xhr.delJSON(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.CLOSING_CONSTRUCTOR.REGISTRY.REMOVE_REGYSTRY(constructedRegistryId)}`,
          )
        ));
      },
      getTemplates(packageId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.DOCUMENTS.GET_TEMPLATES(packageId)}`)
        ));
      },
      addDocument(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.DOCUMENTS.ADD_DOCUMENT}`, data)
        ));
      },
    };
  }

  get Swagger() {
    const self = this;

    return {
      replaceToken() {
        return self.requestWithFreshToken(
          (token) => postCredentials(token, `${CONFIG.API_CLUSTER}${ROUTES.SWAGGER.REPLACE}`),
        );
      },
    };
  }

  get Airport() {
    const self = this;

    return {
      autocomplete(params) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/airline/airports`, params));
      },
      autocompleteAirlines(params) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/airline/airlines`, params));
      },
    };
  }

  get Business() {
    const self = this;

    return {
      loadTripReport(date) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/analytics/sales/${date}`));
      },
      downloadTripReport(date) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token, `${CONFIG.API_ROOT}/analytics/sales/${date}/download`, `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      autoDownloadSendingEdo(runTask) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/diadoc/mailing/auto?runTask=${runTask}`)
        ));
      },
      downloadPaymentCard(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/card-operations/${startDate}/${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      monetaryTransactions(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/card-operations-wl/${startDate}/${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadGeneralActCompletedWorks(companyId, startdate, enddate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/reporting/servicesProvidedDocuments/companies/${companyId}/period/${startdate}/${enddate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadDebtorReport() {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token, `${CONFIG.API_ROOT}/analytics/debtors/download`, `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadSendingTypes() {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/sending-types/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadEditingClosing(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/packages-changes-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadCompaniesDaysInDebt(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/debtor-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadAlvistReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.DOWNLOAD_ALVIST_REPORT(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadAccountsReport() {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token, `${CONFIG.API_ROOT}/analytics/accounts/download`, `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadBookingReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/booking/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadBookingReportWl(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.BOOKING_WL(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadBookingReportSpecialBilling(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.BOOKING_SPECIAL_BILLING(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadBookingReportSec(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/booking2/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadBookingReportThird(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/booking3/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadHotelsReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/hotels/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadHotelsReportPk(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/direct-hotels-closing/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadHotelsPaymentsPk(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/direct-hotels-closing/${startDate}/${endDate}/payments/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadTaxiReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/taxi/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadAirTicketsReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/airtickets/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadIncomingReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/incoming/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadPrintReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/reporting/documents?startDate=${startDate}&endDate=${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadStyleReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-chat/style-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadTagsReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-chat/tags-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadReplyReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-chat/reply-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadKpipoReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-chat/kpipo-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadKpiNoteReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-chat/kpi-note/${startDate}/${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadTimeChatReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-service-reports/time-chat/${startDate}/${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadStopWordReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-chat/stopword-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadConsultationsReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/intercom-chat/consultations-report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadReport1(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/report1/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadTrainTicketsReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/traintickets/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadRefundsOneliyaReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.REFUNDS_ONELIYA(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.FILE}`,
          )
        ));
      },
      downloadProceedsReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/proceeds/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getAirlineAggregationDefault() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/airline-aggregation/schemes/default`)
        ));
      },
      getAirlineAggregationList() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/airline-aggregation/schemes`)
        ));
      },
      getAirlineAggregationProviders() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/airline-aggregation/schemes/providers`)
        ));
      },
      setAirlineAggregationProviders(providersObject) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/airline-aggregation/schemes/providers`, providersObject)
        ));
      },
      getHotelsAggregationProviders() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.HOTEL_AGGREGATION_PROVIDERS}`)
        ));
      },
      setHotelsAggregationProviders(providersObject) {
        return self.requestWithFreshToken((token) => (
          xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.HOTEL_AGGREGATION_PROVIDERS}`, providersObject)
        ));
      },
      setAirlineAggregation(scheme) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/airline-aggregation/schemes/default`, scheme)
        ));
      },
      downloadManagersActions(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/reportonactionsofmanagers/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadOverdraftReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_ROOT}/analytics/overdraft/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadOverdraftReportSec(date) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token, `${CONFIG.API_ROOT}/analytics/overdraft2/${date}/download`, `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadAnalyticsAmoBi(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/marketing-reports/users/${startDate}/${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // Метод не используется, но оставлен целенаправленно, будет удален
      // немного позже.
      downloadGroupingHotel(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/grouping-hotel/${startDate}/${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadHotelVat(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.HOTEL_VAT(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadAAVat(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.AA_VAT(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadSchemas() {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token, `${CONFIG.API_ROOT}/analytics/schemas/download`, `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      downloadReportInsurance(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.INSURANCE(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.FILE}`,
          )
        ));
      },
      downloadReportInsuranceFiltered(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.INSURANCE_FILTERED(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.FILE}`,
          )
        ));
      },
      downloadReportInsuranceConsolidated(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.INSURANCE_CONSOLIDATE(startDate, endDate)}`,
            `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.FILE}`,
          )
        ));
      },
      getReconciliationWithInsurance() {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/insurance-check/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      // Метод не используется, но оставлен целенаправленно, будет удален
      // немного позже.
      getReportClosingDocuments() {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/closings_report/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getSupplierDocuments(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/analytics/supplierdocuments/${startDate}/${endDate}`)
        ));
      },
      sendSupplierDocumentsCheckbox(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/analytics/supplierdocuments/checkbox`, data)
        ));
      },
      getTripByDate(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/backoffice-reports/trip-by-date/${startDate}/${endDate}`)
        ));
      },
      getAboardByDate(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.GET_ABOARD_LIST(startDate, endDate)}`)
        ));
      },
      getApartmentsByDate(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/backoffice-reports/apartments-by-date/${startDate}/${endDate}`)
        ));
      },
      getPrintDocuments(startDate, endDate, companyStart, companyEnd) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(
            token,
            // eslint-disable-next-line @stylistic/max-len
            `${CONFIG.API_ROOT}/reporting/documents/${companyStart}/${companyEnd}?startDate=${startDate}&endDate=${endDate}`,
          )
        ));
      },
      getAviaReport(startDate, endDate, reportType) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/avia/${reportType}/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getAandaVsProviders(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/aa-vs-providers/${startDate}/${endDate}`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getWlRushydro(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/rushydro_sap/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getAandaReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/aanda_report/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getMiceReport(startDate, endDate) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuid(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/mice/${startDate}/${endDate}/download`,
            `${CONFIG.API_CLUSTER}/files/file`,
          )
        ));
      },
      getCheckingStatusPrintDocuments() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/reporting/documents/status`)
        ));
      },
      sendTripByDateCheckboxes(tripId, checkboxes) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/backoffice-reports/trip-by-date/${tripId}/update`, checkboxes)
        ));
      },
      sendAboardDateCheckboxrs(tripItemId, checkboxes) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token, `${CONFIG.API_CLUSTER}${ROUTES.BACKOFFICE_REPORTS.UPDATE_ABOARD_CHECKBOXES(tripItemId)}`, checkboxes,
          )
        ));
      },

      sendApartmentsByDateCheckboxes(tripId, checkboxes) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(
            token, `${CONFIG.API_CLUSTER}/backoffice-reports/apartments-by-date/${tripId}/update`, checkboxes,
          )
        ));
      },
      downloadAutoSendingEmails(startDate, endDate, email) {
        return self.requestWithFreshToken((token) => xhr.downloadWithGuid(
          token,
          `${CONFIG.API_CLUSTER}/backoffice-reports/mail-sending-report/${startDate}/${endDate}/${email}/download`,
          `${CONFIG.API_CLUSTER}/files/file`,
        ));
      },
      startClientSession(email, time) {
        const body = {
          grant_type: 'generate_password',
          client_id: 1,
          client_secret: CONFIG.AUTH.CLIENT_SECRET,
          username: email,
          access_token_ttl: time,
        };

        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CLIENT_SESSION.GENERATE_PASSWORD}`, body)
        ));
      },
    };
  }

  get Booking() {
    const self = this;

    return {
      getBookingSettings() {
        return self.requestWithFreshToken((token) => xhr
          .getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.GET_SETTINGS}`));
      },
      setBookingSettings(data) {
        return self.requestWithFreshToken((token) => xhr
          .putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.SET_SETTINGS}`, data));
      },
      resetBookingSettings() {
        return self.requestWithFreshToken((token) => xhr
          .sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.RESET_SETTINGS}`));
      },
    };
  }

  get ReviseActTest() {
    const self = this;

    return {
      // проблемные акты сверок
      addNewTest() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/analytics/ReviseActTest/AddNewTest`)
        ));
      },
      getTestList() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/analytics/ReviseActTest/teststatuses`)
        ));
      },
      testResult(id) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/analytics/ReviseActTest/result?testId=${id}`)
        ));
      },
    };
  }

  get Unloading1c() {
    const self = this;

    return {
      getPackageDocuments(paramsPackageDocuments) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token,
            `${CONFIG.API_CLUSTER}/one-ass-reports/report`,
            `${CONFIG.API_CLUSTER}/files/file`,
            paramsPackageDocuments,
          )
        ));
      },
      getInfoAmountsSupplier(paramsPackageDocuments) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/one-ass-reports/report/full`, paramsPackageDocuments)
        ));
      },
      getFileXml(guid) {
        return xhr.downloadJSON(`${CONFIG.API_CLUSTER}/files/file`, guid);
      },
      sendSettings(settings) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.UNLOADING1C.SEND_SETTINGS}`, settings)
        ));
      },
      getWorkSheets(body) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.UNLOADING1C.GET_WORKSHEETS}`, body)
        ));
      },
      getPrincipals(body) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.UNLOADING1C.GET_PRINCIPALS}`, body)
        ));
      },
      getNomenclatures(body) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.UNLOADING1C.GET_NOMENCLATURES}`, body)
        ));
      },
    };
  }

  get SendingDiadoc() {
    const self = this;

    return {
      getPackageDocuments(body) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/backoffice-reports/diadoc/mailing/report`, body)
        ));
      },
      downloadXlsDocument(body) {
        return self.requestWithFreshToken((token) => (
          xhr.downloadWithGuidPOST(
            token,
            `${CONFIG.API_CLUSTER}/backoffice-reports/diadoc/mailing/report/xlsx`,
            `${CONFIG.API_CLUSTER}/files/file`,
            body,
          )
        ));
      },
      sendDiadoc(documentsPackageIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_ROOT}/diadoc/mailing`, documentsPackageIds)
        ));
      },
    };
  }

  get AirlineCancel() {
    const self = this;

    return {
      cancelTicket(Provider, PNR) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/airline-service/void`, { Provider, PNR })
        ));
      },
      addAirlineCompany(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/autocomplete-airline/airlines`, data)
        ));
      },
    };
  }

  get AirlineProblemReport() {
    const self = this;

    return {
      getListTemplateWarning() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/informant/template/list/avia`)
        ));
      },
      addFieldTemplateWarning(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/informant/template/add`, data)
        ));
      },
      changeFieldTemplateWarning(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/informant/template/update`, data)
        ));
      },
      deleteFieldTemplateWarning(widgetId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/informant/template/delete/${widgetId}`)
        ));
      },
    };
  }

  get Warning() {
    const self = this;

    return {
      getListTemplateWarning(type) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.WARNING.GET_LIST_TEMPLATE(type)}`)
        ));
      },
      addFieldTemplateWarning(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.WARNING.ADD_TEMPLATE}`, data)
        ));
      },
      changeFieldTemplateWarning(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.WARNING.CHANGE_TEMPLATE}`, data)
        ));
      },
      deleteFieldTemplateWarning(widgetId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.WARNING.DELETE_TEMPLATE(widgetId)}`)
        ));
      },
    };
  }

  get TrainCancel() {
    const self = this;

    return {
      cancelTicket(provider, orderId, ticketId) {
        const body = {
          provider, orderId, ticketId,
        };

        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/train-service/cancel`, body)
        ));
      },
      getListTemplateWarning() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}/informant/template/list/railway`)
        ));
      },
      addFieldTemplateWarning(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/informant/template/add`, data)
        ));
      },
      changeFieldTemplateWarning(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/informant/template/update`, data)
        ));
      },
      deleteFieldTemplateWarning(widgetId) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/informant/template/delete/${widgetId}`)
        ));
      },
    };
  }

  get Slack() {
    return {
      send({
        channel = '#test_channel',
        iconEmoji = ':panic:',
        text = 'nothing to worry about',
        username = 'BOLogger',
      }) {
        const payload = {
          channel, icon_emoji: iconEmoji, text, username,
        };

        return xhr.sendJSONWithoutToken(CONFIG.SLACK_HOOK, payload);
      },
    };
  }

  get TravelPolicy() {
    const self = this;

    return {
      getEmployeeTP(employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.GET_EMPLOYEE_TP(employeeId)}`)
        ));
      },
      getTravelPolicies() {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.GET_TRAVEL_POLICIES}`)
        ));
      },
    };
  }

  get Currency() {
    const self = this;

    return {
      getCurrencyConversionFromTo(data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/currency/convert`, data)
        ));
      },
    };
  }

  get Rights() {
    const self = this;

    return {
      getEmployeeRights(accountId, employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/account/${accountId}/employee/${employeeId}/uac`)
        ));
      },
    };
  }

  get Approval() {
    const self = this;

    return {
      getApprovalScheme(employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/approval-scheme?employeeId=${employeeId}`)
        ));
      },
      getApproversForUser(employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/approve/users/${employeeId}`)
        ));
      },
      getAllApproversForUser(employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_ROOT}/approve/all-users/${employeeId}`)
        ));
      },
      getEmployeesWithRight(employeeId) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_ROOT}/approvers/${employeeId}`));
      },
      getCreateReportsApproval(employeeId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL.ERA_APPROVERS(employeeId)}`)
        ));
      },
      getApprovalSchemaER(userId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL.ERA_APPROVAL_SCHEMES(userId)}`)
        ));
      },
    };
  }

  get Enums() {
    const self = this;

    return {
      getEnums(data) {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.APIW_ROOT}/enums`, { keys: data }));
      },
    };
  }

  get TripTags() {
    const self = this;

    return {
      getTripTags() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.APIW_ROOT}/tags/account`));
      },
    };
  }

  get Calls() {
    const self = this;

    return {
      getCalls() {
        return self.requestWithFreshToken((token) => xhr.getJSON(token, `${CONFIG.API_CLUSTER}/telephony/last-calls`));
      },
    };
  }

  get IntercomNote() {
    const self = this;

    return {
      sendIntercomNote(requestId, chatId) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(
          token,
          `${CONFIG.API_CLUSTER}${ROUTES.INTERCOM.SEND_NOTE(requestId)}`,
          { conversationId: chatId },
        ));
      },

      sendTicketToIntercom(requestId) {
        return self.requestWithFreshToken((token) => xhr.sendJSON(
          token,
          `${CONFIG.API_CLUSTER}${ROUTES.INTERCOM.SEND_TICKET(requestId)}`,
        ));
      },
    };
  }

  get Vacation() {
    const self = this;

    return {
      findAccounts(query) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.VACATION.FIND_ACCOUNTS(query)}`)
        ));
      },
      getUserProfile(companyId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.VACATION.GET_USER_PROFILE(companyId)}`)
        ));
      },
      getTrips(companyId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.VACATION.GET_TRIPS(companyId)}`, data)
        ));
      },
      getPointsTransactions(companyId, data) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.VACATION.GET_POINTS_TRANSACTIONS(companyId)}`, data)
        ));
      },
      // TODO: put there an actual endpoint VACATION.GET_TRAVELLERS
      getTravellers() {

      },
    };
  }

  get CustomAnalytics() {
    const self = this;

    return {
      getAnalyticsWithoutValues(accountId) {
        return self.requestWithFreshToken((token) => (
          xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CUSTOM_ANALYTICS.GET_ANALYTICS_WITHOUT_VALUES(accountId)}`)
        ));
      },

      getAnalyticsByValueId(valueIds) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CUSTOM_ANALYTICS.GET_ANALYTICS_BY_VALUE}`, valueIds)
        ));
      },

      autocompleteAnalytics(accountId, analyticId, query) {
        return self.requestWithFreshToken((token) => (
          xhr.sendJSON(token,
            `${CONFIG.API_CLUSTER}${ROUTES.CUSTOM_ANALYTICS.AUTOCOMPLETE_ANALYTICS(accountId, analyticId)}`,
            `"${query}"`)
        ));
      },
    };
  }
}

const ApiInstance = new Api();

export default ApiInstance;
